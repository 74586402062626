var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"passwordForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-modal',{attrs:{"id":"modal-edit-password","cancel-variant":"outline-secondary","ok-title":"Edit","cancel-title":"Close","centered":"","title":"Edit Password","ok-disabled":false},on:{"ok":_vm.handleEdit}},[_c('b-overlay',{attrs:{"show":_vm.loading,"spinner-variant":"primary","spinner-type":"grow","spinner-small":"","rounded":"sm"}},[_c('b-form',{ref:"resetPasswordForm",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handleEdit)}}},[_c('validation-provider',{attrs:{"name":"Old Password","vid":"old_password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":"old_password"}},[_vm._v("Current Password:")]),_c('b-form-input',{attrs:{"id":"old_password","type":"password","placeholder":"old_password","state":errors.length > 0 ? false:null},model:{value:(_vm.userInfo.old_password),callback:function ($$v) {_vm.$set(_vm.userInfo, "old_password", $$v)},expression:"userInfo.old_password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Password","vid":"old_password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":"password"}},[_vm._v("Password:")]),_c('b-form-input',{attrs:{"id":"password","type":"password","placeholder":"password","state":errors.length > 0 ? false:null},model:{value:(_vm.userInfo.password),callback:function ($$v) {_vm.$set(_vm.userInfo, "password", $$v)},expression:"userInfo.password"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Password Confirmation","vid":"password_confirmation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":"name"}},[_vm._v("Password Confirm:")]),_c('b-form-input',{attrs:{"type":"password","placeholder":"password_confirmation","state":errors.length > 0 ? false:null},model:{value:(_vm.userInfo.password_confirmation),callback:function ($$v) {_vm.$set(_vm.userInfo, "password_confirmation", $$v)},expression:"userInfo.password_confirmation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }