export default [
  {
    title: "儀表盤",
    route: "home",
    icon: "HomeIcon",
    action: "read",
    resource: "Auth",
  },
  {
    title: "員工管理",
    route: "",
    icon: "UsersIcon",
    children: [
      {
        title: "員工",
        route: "users-list",
        resource: "users",
        action: "read",
      },
      {
        title: "團隊",
        route: "teams-list",
        resource: "teams",
        action: "read",
      },
      {
        title: "用戶分類",
        route: "roles-list",
        resource: "roles",
        action: "read",
      },
    ],
  },
  {
    title: "客戶管理",
    route: "",
    icon: "StarIcon",
    children: [
      {
        title: "客戶",
        route: "clients-list",
        resource: "clients",
        action: "read",
      },
      {
        title: "集團",
        route: "groupcodes-list",
        resource: "groupcodes",
        action: "read",
      },
    ],
  },
  {
    title: "產品",
    route: "",
    icon: "StarIcon",
    children: [
      {
        title: "產品",
        route: "products-list",
        resource: "products",
        action: "read",
      },
      {
        title: "供應商",
        route: "suppliers-list",
        resource: "suppliers",
        action: "read",
      },
    ],
  },
  {
    title: "合約管理",
    route: "",
    icon: "ClipboardIcon",
    children: [
      {
        title: "合約",
        route: "contracts-list",
        resource: "contracts",
        action: "read",
      },
    ],
  },
  {
    title: "項目管理",
    route: "",
    icon: "ClipboardIcon",
    children: [
      {
        title: "項目",
        route: "projects-list",
        resource: "projects",
        action: "read",
      },
    ],
  },
  {
    title: "事件日曆",
    route: "meetings",
    icon: "CalendarIcon",
    resource: "Auth",
    action: "read",
  },
  {
    title: "工作指派",
    route: "tasks",
    icon: "CheckSquareIcon",
    resource: "Auth",
    action: "read",
  },
  {
    title: "帳目管理",
    route: "",
    icon: "FileTextIcon",
    children: [
      {
        title: "發票",
        route: "invoices-list",
        resource: "invoices",
        action: "read",
      },
      {
        title: "付款列表",
        route: "payments-list",
        resource: "payments",
        action: "read",
      },
    ],
  },
  {
    title: "管理員",
    route: "",
    icon: "StarIcon",
    children: [
      {
        title: "請假記錄",
        route: "leaverecords-list",
        resource: "leaverecords",
        action: "read",
      },
      {
        title: "加班記錄",
        route: "overtimes-list",
        resource: "overtimes",
        action: "read",
      },
      {
        title: "在家工作記錄",
        route: "works-list",
        resource: "works",
        action: "read",
      },
      {
        title: "全體用戶任務總表",
        route: "admin-tasks",
        resource: "tasks",
        action: "read",
      },
      {
        title: "會議總表",
        route: "admin-meetings",
        resource: "meetings",
        action: "read",
      },
      {
        title: "系統日誌",
        route: "logs-list",
        resource: "logs",
        action: "read",
      },
    ],
  },
  {
    title: "報告系統",
    route: "",
    icon: "FileTextIcon",
    children: [
      {
        title: "員工資料報表",
        route: "reports-user",
        resource: "reports",
        action: "read",
      },
      {
        title: "客戶資料報表",
        route: "reports-client",
        resource: "reports",
        action: "read",
      },
      {
        title: "項目資料報表",
        route: "reports-project",
        resource: "reports",
        action: "read",
      },
      {
        title: "發票報表",
        route: "reports-invoice",
        resource: "reports",
        action: "read",
      },
      {
        title: "收款報表",
        route: "reports-payment",
        resource: "reports",
        action: "read",
      },
    ],
  },
  {
    title: "系統設置",
    route: "",
    icon: "SettingsIcon",
    children: [
      {
        title: "員工",
        children: [
          {
            title: "職位",
            route: "positions-list",
            resource: "positions",
            action: "read",
          },
        ],
      },
      {
        title: "項目",
        children: [
          {
            title: "項目類型",
            route: "servicecodes-list",
            resource: "servicecodes",
            action: "read",
          },
          {
            title: "項目服務",
            route: "applicationcodes-list",
            resource: "applicationcodes",
            action: "read",
          },
          {
            title: "項目狀態",
            route: "projectstages-list",
            resource: "projectstages",
            action: "read",
          },
          {
            title: "項目檔案",
            route: "settings-document-types",
            resource: "documenttypes",
            action: "read",
          },
        ],
      },
      {
        title: "工作指派",
        children: [
          {
            title: "工作指派狀態",
            route: "taskstages-list",
            resource: "taskstages",
            action: "read",
          },
          {
            title: "工作指派標籤",
            route: "tasktags-list",
            resource: "tasktags",
            action: "read",
          },
        ],
      },
      {
        title: "事件日歴",
        children: [
          {
            title: "出席狀態",
            route: "meetingstages-list",
            resource: "meetingstages",
            action: "read",
          },
          {
            title: "會議標籤",
            route: "meetingtags-list",
            resource: "meetingtags",
            action: "read",
          },
        ],
      },
      {
        title: "發票",
        children: [
          {
            title: "支付方法",
            route: "paymentmethods-list",
            resource: "paymentmethods",
            action: "read",
          },
          {
            title: "支付備註",
            route: "payment-remarks",
            resource: "payment-remarks",
            action: "read",
          },
          {
            title: "銷售備註",
            route: "invoice-remarks",
            resource: "invoice-remarks",
            action: "read",
          },
        ],
      },
      {
        title: "產品",
        children: [
          {
            title: "產品品牌",
            route: "product-brands",
            resource: "product-brands",
            action: "read",
          },
          {
            title: "產品類型",
            route: "product-types",
            resource: "product-types",
            action: "read",
          },
        ],
      },
      {
        title: "年假",
        children: [
          {
            title: "請假類型",
            route: "leavecodes-list",
            resource: "leavecodes",
            action: "read",
          },
          {
            title: "假期設定",
            route: "holidays-list",
            resource: "holidays",
            action: "read",
          },
        ],
      },
      {
        title: "編號格式",
        route: "settings-prefixes",
        resource: "prefixes",
        action: "read",
      },
      {
        title: "模版管理",
        children: [
          {
            title: "合約模版",
            route: "contract-template-list",
            resource: "contract-templates",
            action: "read",
          },
          {
            title: "發票模版",
            route: "invoice-template-list",
            resource: "invoice-templates",
            action: "read",
          },
          {
            title: "收據模版",
            route: "receipt-template-list",
            resource: "receipt-templates",
            action: "read",
          },
        ],
      },
      /*   {
        title: "系統設定",
        route: "settings-edit",
        resource: "settings",
        action: "read",
      }, */
    ],
  },
];
